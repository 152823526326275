
//drop downs
// type of service options
export const typeofserviceoptions = [
    { label: "Standard Cleaning", value: "Standard Cleaning" },
    { label: "Standard Cleaning - Move Out", value: "Move-out Clean" },
]

// export construct options
export const constructoptions = [
    { label: "Apartment", value: "Apartment" },
    { label: "House", value: "House" },
    { label: "Room", value: "Room" },
    { label: "Other", value: "Other" },
]

export const numroomsoptions = [
    // { label: "None", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
    { label: "9", value: 9 },
]

export const numbathsoptions = [
    { label: "1", value: 1 },
    { label: "1.5", value: 1.5 },
    { label: "2", value: 2 },
    { label: "2.5", value: 2.5 },
    { label: "3", value: 3 },
    { label: "3.5", value: 3.5 },
    { label: "4", value: 4 },
    { label: "4.5", value: 4.5 },
    { label: "5", value: 5 },
    { label: "5.5", value: 5.5 },
    { label: "6", value: 6 },
    { label: "6.5", value: 6.5 },
    { label: "7", value: 7},
    { label: "7.5", value: 7.5 },
    { label: "8", value: 8 },
    { label: "8.5", value: 8.5 },
]

export const sqftoptions = [{
    label: "less than 250",
    value: 250
},
    {
        label: "Between 251-750",
        value: 750
    },
    {
        label: "Between 751-950",
        value: 950
    },
    {
        label: "Between 951-1250",
        value: 1250
    },
    {
        label: "Between 1251-1750",
        value: 1750
    },
    {
        label: "Between 1751-2500",
        value: 2500
    },
    {
        label: "Between 2501-3500",
        value: 3000
    },
    {
        label: "above 3500",
        value: 3500
    },

]

export const cleanfactoroptions = [
    {
        label: "Move Out - There is no furniture, clutter or garbage - 100% ready to move out clean",
        value: 0,
    },
    {
        label: "There is zero clutter on my floors and surfaces, 100% ready to clean",
        value: 1,
    },
    {
        label: "I have a tiny bit of clutter, 90% ready to clean",
        value: 5,
    },
    {
        label: "There are some things on the floor and stuff on most of the surfaces, 70% ready to clean",
        value: 10,
    },
    {
        label: "There is significant amount of stuff on the floor, beds and other surfaces. 50% ready to clean",
        value: 15,
    },
]
export const numpetsoptions = [
    { label: "0", value: 0 },
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
]
export const numpeopleoptions = [
    { label: "1 - Live Alone or Move Out Clean (include yourself)", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
    { label: "8", value: 8 },
]

export const laundryoptions = [
    { label: "None", value: 0 },
    { label: "1 Load - Wash And Fold", value: 1 },
    { label: "2 Loads - Wash And Fold", value: 2 },
    { label: "3 Loads - Wash And Fold", value: 3 },
    { label: "4 Loads - Wash And Fold", value: 4 },
]

export const dishwashoptions = [
    { label: "None", value: 0 },
    { label: "1 Load - Wash and Dry", value: 1 },
    { label: "2 Load - Wash and Dry", value: 2 },
    { label: "3 Load - Wash and Dry", value: 3 },
]

export const mealprepoptions = [
    { label: "None", value: 0 },
    { label: "1 Meal (serves two)", value: 1 },
    { label: "1 Meal (serves 3)", value: 1.25 },
    { label: "1 Meal (serves 4-6)", value: 1.5 },
    { label: "1 Meal (serves 7-9)", value: 3 },
]
